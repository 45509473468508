import { useEffect, useState } from 'react'
import Lightbox from 'yet-another-react-lightbox'
import Download from 'yet-another-react-lightbox/plugins/download'
import {
  ThemeProvider,
  Container,
  Typography,
  ImageList,
  ImageListItem,
  Tabs,
  Tab,
  Box,
  Button,
  Stack,
  useMediaQuery
} from '@mui/material'
import {
  parseQuery,
  getAuth,
  getImages,
  getSpeeches
} from './helpers/dataHelper'
import theme from './theme'
import AnimText from './components/AnimText'
import 'yet-another-react-lightbox/styles.css'
import './styles.css'

const marginBottom = 16

const Speeches = ({ data }) => {
  const [paragraphs, setParagraphs] = useState(null)
  const [paragraphIndex, setParagraphIndex] = useState(0)
  useEffect(() => {
    if (!data) return
    setParagraphs(null)
    let timeout = setTimeout(() => {
      setParagraphIndex(0)
      setParagraphs(data)
    }, 150)
    return () => clearTimeout(timeout)
  }, [data])
  return paragraphs?.split('\n')?.map((paragraph, index) => {
    const isActive = index <= paragraphIndex
    return (
      <Typography key={index} variant='body1' sx={{ marginBottom: 4 }}>
        {isActive && (
          <AnimText
            onEnd={() => {
              setParagraphIndex(paragraphIndex + 1)
            }}
          >
            {paragraph}
          </AnimText>
        )}
      </Typography>
    )
  })
}

const Image = ({ src, isBeeBee, id, setLightbox, setLightboxIndex }) => {
  const [loading, setLoading] = useState(true)
  return (
    <ImageListItem>
      <img
        className={`list${loading ? '' : ' active'}`}
        src={src}
        alt={'Tap to view'}
        loading='lazy'
        onLoad={() => setLoading(false)}
        onClick={() => {
          if (isBeeBee) return alert('To be continued! 🍼')
          setLightboxIndex(id - 1)
          setLightbox(true)
        }}
      />
    </ImageListItem>
  )
}

function App () {
  const [auth, setAuth] = useState(false)
  const [loading, setLoading] = useState(true)
  const [images, setImages] = useState(null)
  const [speeches, setSpeeches] = useState(null)
  const [lightbox, setLightbox] = useState(false)
  const [lightboxIndex, setLightboxIndex] = useState(0)
  const [tab, setTab] = useState('court')
  const [open, setOpen] = useState(false)
  // const [playing, setPlaying] = useState(false)
  const isMd = useMediaQuery(theme.breakpoints.down('sm'))

  // Load sheet data for user
  useEffect(() => {
    async function getData () {
      const inviteId = parseQuery(window.location.search)
      if (!inviteId) return
      const res = await getAuth(inviteId)
      if (!res) return
      setImages(getImages())
      setSpeeches(getSpeeches())
      setAuth(true)
    }
    getData()
  }, [])

  if (!auth) return <div className='App' />
  return (
    <div className='App'>
      {/* Theme */}
      <ThemeProvider theme={theme}>
        {/* Photo */}
        <Box
          sx={{
            width: '100vw',
            height: isMd ? '40vh' : '66vh',
            marginBottom: isMd ? 6 : 10,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            backgroundColor: '#000'
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/img/hero.jpg`}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              opacity: 0,
              animation: !loading && `animHero 10s 0.3s forwards`
            }}
            onLoad={() => setLoading(false)}
            alt='Court and Matt'
          />
        </Box>

        {/* Intro */}
        <Container maxWidth='md' sx={{ marginBottom }}>
          <Typography
            variant='h2'
            textAlign='center'
            gutterBottom
          >{`Courtenay & Matt's Wedding`}</Typography>
          <Typography
            variant='h1'
            textAlign='center'
            gutterBottom
          >{`Thanks for coming!`}</Typography>
          <Typography
            variant='body1'
            textAlign='center'
            sx={{ marginBottom: 8 }}
          >{`We hope this finds you well, and just wanted to say a huge thankyou for making our wedding day so special. We know these photos have taken a long time to arrive! Please find them, along with everyone's intros and speeches below.`}</Typography>
          <Stack direction='row' justifyContent='center'>
            <Box
              sx={{ width: 32, height: 4, backgroundColor: 'rgb(230, 0, 50)' }}
            />
          </Stack>
        </Container>

        {/* Speeches */}
        <Container maxWidth='md'>
          <Typography
            variant='h2'
            textAlign='center'
            sx={{ marginBottom: 0 }}
          >{`Vows & Speeches`}</Typography>
        </Container>
        <Container maxWidth='sm' sx={{ marginBottom }}>
          <Tabs
            value={tab}
            variant='scrollable'
            scrollButtons='auto'
            allowScrollButtonsMobile
            sx={{ marginBottom: 8 }}
            onChange={(e, val) => setTab(val)}
          >
            <Tab label='Court' value='court' />
            <Tab label='Matt' value='matt' />
            <Tab label='Intros' value='intros' />
            <Tab label='Barry' value='barry' />
            <Tab label='Caroline' value='caroline' />
            <Tab label='Jerry' value='jerry' />
            <Tab label='Daisy' value='daisy' />
            <Tab label='Ollie' value='ollie' />
          </Tabs>
          {speeches?.[tab] && (
            <Box
              className={open ? 'active' : null}
              sx={{
                position: 'relative',
                overflow: 'hidden',
                minHeight: '25vh',
                maxHeight: '25vh',
                transition: 'max-height 0.6s',
                '&.active': {
                  maxHeight: '9999px',
                  transition: 'max-height 0.6s'
                }
              }}
            >
              <Box sx={{ marginBottom: 16 }}>
                <Speeches data={speeches?.[tab]} />
              </Box>

              {!open && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '10vh',
                    background: `linear-gradient(0deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))`
                  }}
                />
              )}
            </Box>
          )}

          <Stack direction='row' justifyContent='center' gap={2}>
            <Button
              variant='contained'
              size='large'
              onClick={() => setOpen(!open)}
            >
              {open ? `Read Less` : `Read More`}
            </Button>
            {/* <Button
              variant='outlined'
              size='large'
              startIcon={playing ? <PauseCircleIcon /> : <PlayCircleIcon />}
              onClick={() => handleSpeech(playing ? 'pause' : 'play')}
            >
              {playing ? `Pause` : `Listen`}
            </Button> */}
          </Stack>
        </Container>

        {/* Photos */}
        {images?.length > 0 && (
          <Container maxWidth='lg' sx={{ marginBottom: 16 }}>
            <Typography
              variant='h2'
              textAlign='center'
              sx={{ marginBottom: 6 }}
            >{`Photos`}</Typography>
            <Typography
              variant='body1'
              textAlign='center'
              sx={{ marginBottom: 8 }}
            >{`Here are a selection of our favourites, you can view the lot at the bottom.`}</Typography>
            <ImageList cols={isMd ? 2 : 4} sx={{ marginBottom: 8 }}>
              {images.map(img => {
                const { id, src } = img
                const isBeeBee = id === 40
                return (
                  <Image
                    key={id}
                    src={src}
                    isBeeBee={isBeeBee}
                    id={id}
                    setLightbox={setLightbox}
                    setLightboxIndex={setLightboxIndex}
                  />
                )
              })}
            </ImageList>
            <Stack direction='row' justifyContent='center'>
              <Button
                variant='contained'
                size='large'
                onClick={() =>
                  window.open(
                    'https://photos.app.goo.gl/dUry36trXgZyZbd27',
                    '_blank'
                  )
                }
              >
                {'View all Photos'}
              </Button>
            </Stack>
          </Container>
        )}
      </ThemeProvider>
      {/* Lightbox */}
      <Lightbox
        slides={images}
        open={lightbox}
        index={lightboxIndex}
        close={() => setLightbox(false)}
        plugins={[Download]}
      />
    </div>
  )
}

export default App
