import React from 'react'

const AnimText = ({ children, delay = 0, onEnd }) => {
  const textArray = children.split(' ')
  return textArray.map((word, index) => {
    const isLast = index === textArray.length - 1
    return (
      <span
        key={index}
        style={{
          opacity: 0,
          animationName: 'animTextIn',
          animationDuration: '150ms',
          animationFillMode: 'forwards',
          animationDelay: `${delay + index * 10}ms`
        }}
        onAnimationEnd={() => isLast && onEnd && onEnd()}
      >
        {`${word} `}
      </span>
    )
  })
}

export default AnimText
