import speeches from './data/speeches.json'

const auth = process.env.REACT_APP_AUTH

const imageCount = 40

const parseQuery = location => {
  const query = '?invite='
  if (!location || !location.includes(query)) {
    return false
  }
  return location.replace(query, '')
}

const getAuth = async id => {
  if (!id) return false
  if (id !== auth) return false
  return true
}

const getImages = () => {
  return new Array(imageCount).fill(0).map((_, index) => {
    const id = index + 1
    return {
      id,
      src: `${process.env.PUBLIC_URL}/img/${id}.jpg`
    }
  })
}

const getSpeeches = () => {
  // Use this to convert:
  // https://onlinetexttools.com/json-stringify-text
  return speeches
}

export { parseQuery, getAuth, getImages, getSpeeches }
