import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    background: {
      default: '#fff',
      paper: '#fff'
    },
    primary: {
      main: 'rgb(230, 0, 50)',
      contrastText: 'rgb(230, 0, 50)'
    },
    text: {
      primary: '#ff0000',
      secondary: '#ff0000'
    }
  },
  typography: {
    allVariants: {
      color: '#000'
    },
    h1: {
      fontFamily: `'Ibarra Real Nova', serif`,
      fontWeight: 400,
      fontSize: '4rem',
      lineHeight: '110%',
      letterSpacing: '-0.05rem'
    },
    h2: {
      fontFamily: `'Ibarra Real Nova', serif`,
      fontWeight: 400,
      fontSize: '2rem',
      lineHeight: '110%'
    }
  },
  shape: {
    borderRadius: 8
  },
  spacing: factor => `${0.25 * factor}rem`,
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: 16
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          borderRadius: 999,
          color: '#fff',
          textTransform: 'none',
          fontFamily: `'Roboto', sans-serif`,
          fontWeight: 700,
          padding: '0 2rem',
          height: '3rem',
          opacity: 1,
          transition: 'opacity 0.3s',
          '&:hover': {
            filter: 'none',
            opacity: 0.6,
            transition: 'opacity 0.3s'
          }
        },
        contained: {
          backgroundColor: 'rgb(230, 0, 50)',
          '&:hover': {
            backgroundColor: 'rgb(230, 0, 50)'
          }
        },
        outlined: {
          color: '#000',
          border: '2px solid rgba(0,0,0,0.2)',
          backgroundColor: '#fff',
          '&:hover': {
            border: '2px solid rgba(0,0,0,0.2)',
            backgroundColor: '#fff'
          }
        }
      }
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true
      },
      styleOverrides: {
        root: {
          alignItems: 'flex-start',
          padding: 0,
          minWidth: 'auto',
          textTransform: 'none',
          marginRight: '1rem',
          fontSize: '1rem',
          fontWeight: 500,
          color: 'rgba(0,0,0,0.8)'
        }
      }
    },
    MuiImageListItem: {
      styleOverrides: {
        root: {
          opacity: 1,
          transition: 'opacity 0.3s',
          '&:hover': {
            cursor: 'pointer',
            opacity: 0.8,
            transition: 'opacity 0.3s'
          }
        }
      }
    }
  }
})

export default theme
